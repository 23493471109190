#xuehua {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-color: #000; */
  background-image: url('https://api.weibinyi.com/images/niannian/yhc.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 95%;
    width: 2.133333rem;
  }

  to {
    opacity: 0.3;
    top: -0.266667rem;
    width: 0.533333rem;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 95%;
    width: 2.133333rem;
  }

  to {
    opacity: 0.3;
    top: -0.266667rem;
    width: 0.533333rem;
  }
}

.snowflake {
  position: fixed;
  color: white;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 9999;
  /* pointer-events: none; */
  -webkit-animation: fadeOut 8000ms linear;
  animation: fadeOut 8000ms linear;
}