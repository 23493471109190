/*
KISSY CSS Reset
理念：1. reset 的目的不是清除浏览器的默认样式，这仅是部分工作。清除和重置是紧密不可分的。
2. reset 的目的不是让默认样式在所有浏览器下一致，而是减少默认样式有可能带来的问题。
3. reset 期望提供一套普适通用的基础样式。但没有银弹，推荐根据具体需求，裁剪和修改后再使用。
特色：1. 适应中文；2. 基于最新主流浏览器。
维护：玉伯<lifesinger@gmail.com>, 正淳<ragecarrier@gmail.com>
 */

@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url('webfont.eot');
  /* IE9 */
  src: url('webfont.eot?#iefix') format('embedded-opentype'), 
  url('webfont.woff2') format('woff2'),
  url('webfont.woff') format('woff'), 
  url('webfont.ttf') format('truetype'), 
  url('webfont.svg#webfont') format('svg');
  /* iOS 4.1- */
}

.web-font {
  font-family: "webfont" !important;
  font-size: 0.426667rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}