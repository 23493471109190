/* html,body {
	margin:0;
	overflow:hidden;
	width:100%;
	height:100%;
	cursor:none;
	background:black;
	background:linear-gradient(to bottom,#000000 0%,#5788fe 100%);
} */

.home {
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: none;
  background: black;
  background: linear-gradient(to bottom,#000000 0%,#5788fe 100%);
}

.filter {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fe5757;
  -webkit-animation: colorChange 30s ease-in-out infinite;
  animation: colorChange 30s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  mix-blend-mode: overlay;
}

@-webkit-keyframes colorChange {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: .9;
  }
}

@keyframes colorChange {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: .9;
  }
}

.landscape {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-image:url(https://openclipart.org/image/2400px/svg_to_png/250847/Trees-Landscape-Silhouette.png);
	*/
  background-image: url('../../assets/img/xkbg.png');
  background-size: 26.666667rem 6.666667rem;
  background-repeat: repeat-x;
  background-position: center bottom;
}